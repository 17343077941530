<template>
    <div>
        <p class="font-size-lg mt-4">{{$t('PROFILE.SECTIONS.IDENTITY_CARD.HEADER')}}</p>
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.id_card_serial.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.id_card_serial.name]"
                                :ref="fields.id_card_serial.name"
                                :name="fields.id_card_serial.name"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.id_card_number.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.id_card_number.name]"
                                :ref="fields.id_card_number.name"
                                :name="fields.id_card_number.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row">
                   <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.id_card_expiration_date.label }} *
                            </label>
                            <st-datepicker
                                v-model="model[fields.id_card_expiration_date.name]"
                                :ref="fields.id_card_expiration_date.name"
                                :name="fields.id_card_expiration_date.name"
                                :min="minDate"
                                @change="validateField(fields.id_card_expiration_date.name)"
                                size="sm"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.id_card_issuer.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.id_card_issuer.name]"
                                :ref="fields.id_card_issuer.name"
                                :name="fields.id_card_issuer.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { idCard } from '@/shared/utils/id-card';
export default {
    props: {
        model: {
            type: Object,
        },
        fields: {
            type: Object,
        },
        formValidation: {
            type: Object
        },
        idCardFields: {
            type: Array
        }
    },
    data() {
        return {
            minDate: idCard.expirationDate
        }
    },
    mounted() {
        if (this.formValidation) {
            this.idCardFields.forEach((field) => {
                this.formValidation.addField(field, {
                    validators: {
                        notEmpty: {
                            message: this.$t('VALIDATION.REQUIRED_FIELD'),
                        }
                    }
                });
            })
        }
    },
    methods: {
        validateField(field) {
            this.formValidation.validateField(field);
        },
    }
}
</script>
